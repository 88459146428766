import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layout/layout';
import { Container } from 'reactstrap';
import { TextBlock } from '../../../components/shared/text-block';
import { PRIMARY_TEXT_COLOR } from '../../../constants';
import { Wrapper } from '../../../components/shared/wrapper';
import { Fullscreen } from '../../../components/fullscreen/fullscreen';
import { Background } from '../../../components/background/background';
import cover from '../../../../static/images/services/cover.png';
import { BadgeLanguage, TitleContainer, TitleText } from '../../../components/shared';
import Link from '../../../components/shared/link';
import * as QueryString from 'query-string';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 370px) {
        flex-direction: column;
    }
    justify-content: start;
    align-items: start;
`;

const MobileDev = props => {
    const [state, setState] = useState(true);

    useEffect(() => {
        let parsed = QueryString.parse(props.location.search);
        if (parsed.language === 'sl') {
            setState(false);
        }
    }, [props.location.search]);

    const changeLanguage = language => {
        if (state !== language) {
            window.location = `${window.location.pathname}?language=${language ? 'eng' : 'sl'}`;
        }
    };
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Equaleyes - Mobile developer</title>
            </Helmet>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <Background bg={cover} design={'cover'} index={1} />
                <TitleContainer>
                    <TitleText>{state ? 'Mobile developer' : 'Razvijalec/ka mobilnih aplikacij'}</TitleText>
                </TitleContainer>
            </Fullscreen>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Container>
                    <BadgeLanguage>
                        <span className={state ? 'black' : null} onClick={() => changeLanguage(true)}>
                            Eng
                        </span>
                        <span className={state ? null : 'black'} onClick={() => changeLanguage(false)}>
                            Slo
                        </span>
                    </BadgeLanguage>
                    {state ? (
                        <>
                            <TextBlock
                                title={
                                    <>
                                        <p>What we're after</p>
                                        <div className="mb-5">
                                            <span className="badge badge-warning mr-2">Kotlin</span>
                                            <span className="badge badge-warning mr-2">Java</span>
                                            <span className="badge badge-warning mr-2">Swift</span>
                                        </div>
                                    </>
                                }
                            >
                                <p>
                                    We are looking for a proactive, curious and responsible coworker to join our team in Maribor, Slovenia.
                                </p>
                            </TextBlock>
                            <TextBlock title={'Responsibilities for a Mobile developer'}>
                                <ul>
                                    <li>Planning and developing small mobile projects</li>
                                    <li>Participating in the development of complex mobile projects</li>
                                    <li>Assistance in planning application architecture</li>
                                    <li>Coordinating with other development departments in the company</li>
                                    <li>Further training to achieve professional growth</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Qualifications for a Mobile developer'}>
                                <ul>
                                    <li>
                                        Minimum 2 years of work experience with at least one of the following technologies:
                                        <ul>
                                            <li>Android: Java or Kotlin</li>
                                            <li>iOS: Swift</li>
                                        </ul>
                                    </li>
                                    <li>Experience with agile programming processes</li>
                                    <li>Excellent problem-solving skills and tackling technical challenges</li>
                                    <li>Willingness to learn and develop</li>
                                    <li>Independence at work and openness to new ideas</li>
                                    <li>Ability to share knowledge and experience with others</li>
                                    <li>Good written and oral expression in Slovene and English (active knowledge of English)</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'What we offer'}>
                                <ul>
                                    <li>Work in a connected and highly qualified team</li>
                                    <li>
                                        Employment Contract for an indefinite period (6 months of probation period)
                                    </li>
                                    <li>Competitive salary, suitable to experiences</li>
                                    <li>
                                        Work on interesting and recognizable projects (NKBM - mBanka@Net, MindCandy - Moshi Twilight,
                                        Autistica - Molehill Mountain app and others)
                                    </li>
                                    <li>A dynamic and relaxed work environment</li>
                                    <li>Flexible working hours and work from home</li>
                                    <li>Further learning</li>
                                    <li>Sports activities (good posture)</li>
                                    <li>
                                        Participation in{' '}
                                        <a href={'https://techcrunch.com/2015/12/06/disrupt-london-2015-hackathon-winners/?guccounter=1'} rel={"nofollow"}>
                                            hackathons.
                                        </a>
                                    </li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Are you interested?'}>
                                <p>If the position interest you, make sure to apply as soon as possible!</p>
                                <ButtonWrapper>
                                    <Link to="https://forms.office.com/r/q5eimj2HPE" className="mt-2">
                                        <button className="btn btn-warning mr-2">Apply</button>
                                    </Link>
                                    <Link to="https://forms.office.com/r/NQ4BBQ084M" className="mt-2">
                                        <button className="btn btn-link">I want to know more</button>
                                    </Link>
                                </ButtonWrapper>
                            </TextBlock>
                        </>
                    ) : (
                        <>
                            <TextBlock
                                title={
                                    <>
                                        <p>Iščemo</p>
                                        <div className="mb-5">
                                            <span className="badge badge-warning mr-2">Kotlin</span>
                                            <span className="badge badge-warning mr-2">Java</span>
                                            <span className="badge badge-warning mr-2">Swift</span>
                                        </div>
                                    </>
                                }
                            >
                                <p>
                                    V našo ekipo vabimo proaktivno, radovedno in odgovorno osebo, ki je vajena ekipnega dela in se ne boji
                                    izzivov. Delo poteka na sedežu podjetja v centru Maribora, z možnostjo občasnega dela na daljavo.
                                </p>
                            </TextBlock>
                            <TextBlock title={'Tvoji izzivi'}>
                                <ul>
                                    <li>Načrtovanje in razvijanje manjših mobilnih projektov</li>
                                    <li>Sodelovanje pri razvoju kompleksnih mobilnih projektov</li>
                                    <li>Pomoč pri načrtovanju arhitekture aplikacij</li>
                                    <li>Usklajevanje z ostalimi razvojnimi oddelki v podjetju</li>
                                    <li>Dodatno izobraževanje za doseganje nadaljnje strokovne rasti</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Naša pričakovanja'}>
                                <ul>
                                    <li>
                                        Minimalno 2 leti delovnih izkušenj z vsaj eno izmed spodaj naštetih tehnologij:
                                        <ul>
                                            <li>Android: Java ali Kotlin</li>
                                            <li>iOS: Swift</li>
                                        </ul>
                                    </li>
                                    <li>Izkušnje z agilnimi procesi razvoja</li>
                                    <li>Odlične sposobnosti za reševanje problemov in spopadanje s tehničnimi izzivi</li>
                                    <li>Pripravljenost na učenje in razvoj</li>
                                    <li>Samostojnost pri delu in odprtost za nove ideje</li>
                                    <li>Deljenje znanja in izkušenj z ostalimi</li>
                                    <li>
                                        Dobro pisno in ustno izražanje v slovenskem in angleškem jeziku (aktivno znanje angleškega jezika)
                                    </li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Nudimo ti'}>
                                <ul>
                                    <li>Delo v povezani in visoko usposobljenosti ekipi</li>
                                    <li>
                                        Poskusna doba 6 mesecev - zaposlitev za določen čas z opcijo kasnejše zaposlitve za nedoločen čas
                                    </li>
                                    <li>Konkurenčna plača, primerna izkušnjam</li>
                                    <li>
                                        Delo na zanimivih in prepoznavnih projektih (NKBM - mBanka@Net, MindCandy - Moshi Twilight,
                                        Autistica - Molehill Mountain app in drugi)
                                    </li>
                                    <li>Dinamično in sproščeno delovno okolje</li>
                                    <li>Fleksibilni delovni čas in delo od doma</li>
                                    <li>Spodbujanje nadaljnjega učenja</li>
                                    <li>Športne dejavnosti (telovadba za boljšo držo)</li>
                                    <li>
                                        Sodelovanje na{' '}
                                        <a href={'https://techcrunch.com/2015/12/06/disrupt-london-2015-hackathon-winners/?guccounter=1'} rel={"nofollow"}>
                                            hackathonih.
                                        </a>
                                    </li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Te zanima?'}>
                                <p>V kolikor se prepoznate v zgornjem opisu vas vabimo, da se čimprej prijavite!</p>
                                <ButtonWrapper>
                                    <Link
                                        to="https://forms.office.com/r/peaTBbv844"
                                        className="mt-2"
                                    >
                                        <button className="btn btn-warning mr-2">Prijava</button>
                                    </Link>
                                    <Link to="https://forms.office.com/r/SzdrNdTctz" className="mt-2">
                                        <button className="btn btn-link">Zanima me več</button>
                                    </Link>
                                </ButtonWrapper>
                            </TextBlock>
                        </>
                    )}
                </Container>
            </Wrapper>
        </Layout>
    );
};

export default MobileDev;
