import Layout from '../components/layout/layout';
import React from 'react';
import styled from 'styled-components';
import { Fullscreen } from '../components/fullscreen/fullscreen';

import { PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR, TEXT_FONT, TITLE_FONT } from '../constants';
import { Helmet } from 'react-helmet';
import { Chat } from '../components/contact/chat';

const Wrapper = styled.div`
    height: 100%;
    background-color: ${props => props.color};
    padding-top: 130px;
    padding-bottom: 100px;
    color: ${PRIMARY_TEXT_COLOR};
    font-family: ${TITLE_FONT};
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;

    p {
        font-family: ${TITLE_FONT};
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.1px;
        text-align: center;
        color: #363636;
    }

    h1 {
        color: ${props => props.txtColor} !important;
        font-family: ${TITLE_FONT};
        font-size: 26px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -1.3px;
        text-align: center;
    }

    h3 {
        font-family: ${TEXT_FONT};
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: ${PRIMARY_TITLE_COLOR};
        margin-bottom: 20px;

        @media (max-width: 768px) {
            font-size: 20px;
        }
    }

    .option {
        background-color: #101520;
        color: ${PRIMARY_TEXT_COLOR};
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        margin-right: 20px !important;
        max-lines: 2 !important;
    }
`;

export default () => {
    return (
        <Layout bgColor={'black'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Equaleyes - Quote</title>
            </Helmet>
            <Fullscreen className="d-flex justify-content-center flex-column position-relative">
                <Wrapper color={'#f7f7f7'}>
                    <Chat />
                </Wrapper>
            </Fullscreen>
        </Layout>
    );
};
