import React from 'react';
import Layout from '../components/layout/layout';

import { PRIMARY_TEXT_COLOR } from '../constants';
import { Wrapper } from '../components/shared/wrapper';
import { Fullscreen } from '../components/fullscreen/fullscreen';

import { TitleContainer, TitleText } from '../components/shared';
import Container from 'reactstrap/es/Container';
import { TextBlock } from '../components/shared/text-block';
import Link from '../components/shared/link';
import { Helmet } from 'react-helmet';

export default () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Equaleyes - Privacy</title>
            </Helmet>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <TitleContainer>
                    <TitleText>Privacy Notice</TitleText>
                </TitleContainer>
            </Fullscreen>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Container>
                    <TextBlock title="Introduction">
                        <p>
                            “Equaleyes”, "We", "our" or "us" means Equaleyes d.o.o., Brnčičeva 39, 1231 Ljubljana - Črnuče, Slovenia. Our
                            company registration number is 1662163000 and our VAT registration number is 83782796. For the purposes of data
                            protection law, we are a data controller in respect of your personal data. We collect and use your personal data
                            and where applicable this may include information related to your spouse/partner, directors, partners and owners
                            (your “representatives”). Equaleyes is responsible for ensuring that it uses your personal data in compliance
                            with data protection law.{' '}
                        </p>
                        <p>
                            If you have any questions about this notice, about how we process personal data or about your rights over your
                            personal data, our Chief Executive Officer can be contacted at contact@equaleyes.com. This notice applies to any
                            personal data we receive from you, create or obtain from other sources and explains how it will be used by us.
                            It is important that you take the time to read this notice so that you understand how we will use your personal
                            data and your rights in relation to your personal data.
                        </p>
                    </TextBlock>
                    <TextBlock title="Information you give us">
                        <p>
                            This is information about you and your representatives that you give us by filling in forms or by corresponding
                            with us by telephone, e-mail or otherwise. The information you give us may include your and your
                            representatives’ name, address, e-mail address and telephone number, financial information, employment history,
                            identification records, qualifications and vehicle details.
                        </p>
                        <p>
                            You must ensure that in respect of any information you provide us with, which does not relate to you (for
                            example, information about your representatives), you have obtained the necessary consent in order to disclose
                            such information and provided the individual to whom the information relates with a copy of this notice.
                        </p>
                    </TextBlock>
                    <TextBlock title="Information we collect or generate about you and your representatives">
                        <h4 className="title">Website Usage Information</h4>
                        <p>
                            Our website (https://equaleyes.com/) uses Google Analytics to automatically gather certain statistical
                            information such as the number and frequency of visitors and their IP addresses. This information is used as
                            aggregated statistical information about users, providing usage by IP address. This information helps us to
                            measure how individuals use the website and our services, so that we can continually improve them.
                        </p>
                        <h4 className="title">Use of Cookies</h4>
                        <p>
                            For full details on what cookies are, what they do, and which cookies are used by us, please see our{' '}
                            <Link to="cookies">Cookies Policy</Link>.
                        </p>
                        <p>
                            To enable you to sign in and personalise your online experience we use “cookies”. A cookie is a small text file
                            that is placed on your hard disk by a web page server. Cookies contain information that can later be read by a
                            web server in the domain that issued the cookie to you. Cookies cannot be used to run programs or deliver
                            viruses to your computer. We use cookies to store your preferences and other information on your computer in
                            order to save you time by eliminating the need to repeatedly enter the same information and to display your
                            personalised content and appropriate advertising on your later visits to our website.
                        </p>
                    </TextBlock>
                    <TextBlock title="Information we receive from other sources">
                        <h4 className="title">Social Media Profile</h4>
                        <p>
                            Where you have clicked through one of our promotions or event notices posted on a third party social media
                            website such as LinkedIn, we will receive your contact information provided as part of your user profile such as
                            your name, e-mail address and telephone number and any other relevant business information such as your
                            organisation’s name, address and your industry. Some of this information may be pre-populated based on your
                            social media profile; other parts may be completed by you, as and when requested.
                        </p>
                    </TextBlock>
                    <TextBlock title="How we use your personal data">
                        <p>Your personal data may be used by us in the following ways:</p>
                        <ul>
                            <li>to provide you with information, products or services that you may request from us;</li>
                            <li>to carry out our obligations arising from any agreements you enter into with us;</li>
                            <li>
                                where we are permitted to do so, to send promotional information about our products and services via methods
                                such as e-mail, post, telephone, etc.; and
                            </li>
                            <li>
                                to contact you via post, e-mail or telephone in relation to the administration of your account or to carry
                                out quality control research.
                            </li>
                        </ul>
                    </TextBlock>
                    <TextBlock title="Legal basis for processing your personal data">
                        <p>We process your personal data pursuant to the following legal bases:</p>
                        <ul>
                            <li>
                                your consent to share your personal data with third party affiliates which may wish to collaborate with
                                Equaleyes on you project;
                            </li>
                            <li>
                                taking steps (at your request) prior to entering into an agreement with you, and subsequently for the
                                administration and performance of our agreement with you;
                            </li>
                            <li>to comply with our legal and regulatory obligations;</li>
                            <li>
                                to establish, exercise or defend our legal rights and/or for the purpose of (or in connection with) legal
                                proceedings; and
                            </li>
                            <li>
                                the use of your personal data as described is necessary for our legitimate business interests which are:
                                <ul>
                                    <li>enforcing the terms and conditions of any agreement we have with you;</li>
                                    <li>the recovery of outstanding debts existing under an agreement with you;</li>
                                    <li>for statistical analysis to improve our products and services; or</li>
                                    <li>
                                        to contact you about products and services that may be of interest to you. You may object to this at
                                        any time by contacting us at slo-info@nchain.com or by any other contact details below.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </TextBlock>
                    <TextBlock title="Sharing your personal data">
                        <p>
                            <strong>We may disclose your personal data within Equaleyes</strong> and to third party service providers in the
                            circumstances described below:
                        </p>
                        <ul>
                            <li>to ensure the delivery or maintenance of products or services you have taken out with us;</li>
                            <li>to ensure the safety and security of our data; and</li>
                            <li>as part of our internal research and statistical analysis activity.</li>
                        </ul>
                        <p>
                            We will take steps to ensure that the personal data is accessed only by personnel that have a need to do so for
                            the purposes described in this notice.
                        </p>
                        <p>
                            <strong>We may also share your personal data outside of Equaleyes</strong>
                        </p>
                        <ul>
                            <li>
                                to our professional advisers in order to enforce or apply the terms of use and other agreements you have
                                with us;
                            </li>
                            <li>
                                to an insurer (including insurers in connection with insured products and services) or insurers for
                                administration;
                            </li>
                            <li>to claims handlers and fraud prevention agencies;</li>
                            <li>to any guarantor;</li>
                            <li>to any funder in order to enable funders to assess the value of our assets;</li>
                            <li>to any subcontractor of an agreement with us;</li>
                            <li>
                                if we sell any of our business or assets, in which case we may disclose your personal data to the
                                prospective buyer for due diligence purposes;
                            </li>
                            <li>
                                if we are acquired by a third party, in which case personal data held by us about you will be disclosed to
                                the third party buyer;
                            </li>
                            <li>
                                to third party agents or contractors (for example, the providers of our electronic data storage services or
                                call centres and/or third party debt managers or collectors) for the purposes of providing services to us;
                                and
                            </li>
                            <li>
                                with your consent, to third party affiliates who may wish to offer you products and services which may be of
                                interest to you.
                            </li>
                        </ul>
                        <p>
                            These third parties will be subject to confidentiality requirements and they will only use your personal data as
                            described in this privacy notice.
                        </p>
                        <p>
                            <strong>We may also share your personal data outside of Equaleyes</strong> to the extent required by law, for
                            example if we are under a duty to disclose your personal data in order to comply with any legal obligation
                            including but not limited to disclosures made to Companies House; and to establish, exercise or defend our legal
                            rights.
                        </p>
                    </TextBlock>
                    <TextBlock title="Transfer of personal data outside the European Economic Area">
                        <p>
                            The information you provide to us will be transferred to and stored on our secure servers in the European
                            Economic Area (“EEA”). However, from time to time, your personal data may be transferred to, stored in, or
                            accessed from a destination outside the EEA. It may also be processed by staff operating outside of the EEA who
                            work for a company in Equaleyes or for one of our subcontractors.
                        </p>
                        <p>
                            Where we transfer your personal data outside the EEA, we will ensure that it is protected in a manner that is
                            consistent with how your personal data will be protected by us in the EEA. This can be done in a number of ways,
                            for instance:
                        </p>
                        <ul>
                            <li>
                                the country that we send the data to might be approved by the European Commission or a relevant data
                                protection authority;{' '}
                            </li>
                            <li>
                                the recipient might have signed up to a contract based on “model contractual clauses” approved by the
                                European Commission, obliging them to protect your personal data; or
                            </li>
                            <li>
                                where the recipient is located in the US, it might be a certified member of the EU-US Privacy Shield scheme.{' '}
                            </li>
                        </ul>
                        <p>
                            In other circumstances the law may permit us to otherwise transfer your personal data outside the EEA. In all
                            cases, however, we will ensure that any transfer of your personal data is compliant with data protection law.
                        </p>
                        <p>
                            You can obtain more details of the protection given to your personal data when it is transferred outside the EEA
                            (including a copy of the standard data protection clauses which we have entered into with recipients of your
                            personal data) by contacting us in accordance with the “Contact us” section below.
                        </p>
                    </TextBlock>
                    <TextBlock title="How long we keep your personal data">
                        <p>
                            How long we hold your personal data for will vary. The retention period will be determined by various criteria
                            including:
                        </p>
                        <ul>
                            <li>
                                the purpose for which we are using it – we will need to keep the data for as long as is necessary for that
                                purpose; and
                            </li>
                            <li>
                                legal obligations – laws or regulation may set a minimum period for which we have to store your personal
                                data.
                            </li>
                            <li>
                                If you are a customer we will retain your personal data for 8 years following the end of our relationship
                                with you, unless we are required by law to keep it for a longer period of time (in which case, we will keep
                                it until the expiry of the period required by law).
                            </li>
                        </ul>
                    </TextBlock>
                    <TextBlock title="Your rights">
                        <p>You have a number of rights in relation to the personal data that we hold about you. These rights include:</p>
                        <ul>
                            <li>
                                the right to object to our processing of your personal data where we process your personal data pursuant to
                                our legitimate business interests. Please note that there may be circumstances where you object to our
                                processing of your personal data but may be legally entitled to refuse that request;
                            </li>
                            <li>
                                the right to obtain information regarding the processing of your personal data and access to the personal
                                data which we hold about you;
                            </li>
                            <li>
                                the right to withdraw your consent to our processing of your personal data at any time. Please note,
                                however, that we may still be entitled to process your personal data if we have another legitimate reason
                                (other than consent) for doing so;
                            </li>
                            <li>
                                in some circumstances, the right to receive some personal data in a structured, commonly used and
                                machine-readable format and/or request that we transmit those data to a third party where this is
                                technically feasible. Please note that this right only applies to personal data which you have provided to
                                us;
                            </li>
                            <li>the right to request that we rectify your personal data if it is inaccurate or incomplete;</li>
                            <li>
                                the right to request that we erase your personal data in certain circumstances. Please note that there may
                                be circumstances where you ask us to erase your personal data but we are legally entitled to retain it;
                            </li>
                            <li>
                                the right to request that we restrict our processing of your personal data in certain circumstances. Please
                                note that there may be circumstances where you ask us to restrict our processing of your personal data but
                                we are legally entitled to refuse that request;
                            </li>
                            <li>
                                the right to object to any automated decision making (including profiling) which we conduct based on your
                                personal data, which significantly affects you. Please note that there may be circumstances where you object
                                to us conducting automated decision making but we are legally entitled to refuse that request;
                            </li>
                            <li>the right to object to the processing of your personal data for direct marketing purposes; and</li>
                            <li>
                                the right to lodge a complaint with the data protection regulator (details of which are provided below) if
                                you think that any of your rights have been infringed by us.
                            </li>
                        </ul>
                        <p>You can exercise your rights by contacting us using the details set out in the “Contacting us” section below.</p>
                        <p>
                            You can find out more information about your rights by contacting the data protection regulator, the Information
                            Commissioner, or by searching their website at{' '}
                            <a href="https://ico.org.uk/" rel={'nofollow'}>
                                https://ico.org.uk
                            </a>
                            .
                        </p>
                    </TextBlock>
                    <TextBlock title="Changes to our privacy policy">
                        <p>
                            We keep our{' '}
                            <Link to="https://drive.google.com/file/d/1Bm2ptHhODEb26TxsluHiuD38Asa-RJIe/view?usp=sharing">
                                Privacy policy
                            </Link>{' '}
                            under regular review.
                        </p>
                        <p>
                            Any changes we make to our privacy policy in the future will be posted on this page and, where appropriate,
                            notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy.
                        </p>
                    </TextBlock>
                    <TextBlock title="Contact us">
                        <p>We are registered in SLO and our registered address is at </p>
                        <address>
                            Equaleyes d.o.o.
                            <br />
                            Brnčičeva 39,
                            <br />
                            1231 Ljubljana - Črnuče,
                            <br />
                            Slovenia
                        </address>
                        <p>and our company registration number is 83782796. </p>
                        <p>Please contact us if you have any questions about our Privacy policy or personal data we hold about you: </p>
                        <p>Write to us at our address or get in touch</p>
                        <p>
                            By telephone: <a href="tel:+386 1 518 66 81">+386 1 518 66 81</a> or
                        </p>
                        <p>
                            By e-mail: <a href="mailto:contact@equaleyes.com">contact@equaleyes.com</a>{' '}
                        </p>
                    </TextBlock>
                </Container>
            </Wrapper>
        </Layout>
    );
};
