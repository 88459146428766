import { PRIMARY_BG_COLOR, PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR } from '../constants';
import { Fullscreen } from '../components/fullscreen/fullscreen';
import { Background } from '../components/background/background';
import React from 'react';
import { Col, Container } from 'reactstrap';
import Layout from '../components/layout/layout';
import { Helmet } from 'react-helmet';
import { LetsGetStarted } from '../components/shared/lets-get-started';

import styled from 'styled-components';
import { TitleText } from '../components/shared';
import { TextBlock } from '../components/shared/text-block';
import { ImageGrid } from '../components/about/image-grid';
import { TwoRows } from '../components/shared/two-rows';

import cover from '../../static/images/about/cover.webp';
import team_image from '../../static/images/people/team-horizontal.jpg';
import team_vertical from '../../static/images/people/team-vertical.jpg';

import clutch from '../../static/images/awards/clutch.svg';
import goodfirm from '../../static/images/awards/goodfirms.svg';
import disrupt2015 from '../../static/images/awards/disrupt2015.svg';
import disrupt2016 from '../../static/images/awards/disrupt2016.svg';
import unltd from '../../static/images/awards/unltd.svg';
import mtfp from '../../static/images/awards/mtfp.svg';
import nt from '../../static/images/awards/nominet-trust.svg';
import Link from '../components/shared/link';
import { useScrollingPage } from '../components/shared/scrolling-page.hook';
import { MAX_PAGES } from '.';

const TitleContainer = styled(Container)`
    z-index: 1;
`;

const Wrapper = styled.div`
    height: 100%;
    background-color: ${props => props.color};
    padding-bottom: 50px;

    &:not(:first-of-type) {
        padding-top: 50px;
    }
`;

const TeamImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export default () => {
    const { canScroll, setCanScroll } = useScrollingPage(MAX_PAGES);

    return (
        <Layout showShadow={true} setCanScroll={setCanScroll} canScroll={canScroll} bgColor={PRIMARY_BG_COLOR}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Equaleyes - About us</title>
            </Helmet>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                    <Background bg={cover} design={'cover'} index={1} blur={2.5} />
                    <TitleContainer>
                        <TitleText>About us.</TitleText>
                    </TitleContainer>
                </Fullscreen>
                <Container>
                    <TextBlock title={'About us.'}>
                        <h2>We build the future of software with award-winning mobile, web, and blockchain solutions.</h2>
                        <p>
                            We build the future of software with award-winning mobile, web, and blockchain solutions. At Equaleyes, we
                            provide key technology services to startups and large enterprises, reaching millions of users worldwide. Based
                            in Ljubljana and Maribor, Slovenia, we specialize in web and mobile development, blockchain, backend systems,
                            and UX/UI design.
                        </p>
                        <p>
                            Recently, we developed an award-winning digital banking solution in Slovenia and are leading the way in fintech
                            with secure, scalable mobile banking apps. We have deep experience in blockchain, having built and deployed
                            several blockchain applications.
                        </p>
                        <p>
                            With our startup roots and enterprise experience, we deliver agile, scalable solutions that help businesses grow
                            and succeed.
                        </p>
                    </TextBlock>
                </Container>
            </Wrapper>
            <Wrapper color={'#15171b'}>
                <Container>
                    <TextBlock title={'Our core values.'} txtcolor={PRIMARY_TEXT_COLOR}>
                        <h1>Loyalty.</h1>
                        <h3 className={'space'}>We will fight for you.</h3>
                        <h1>Bravery.</h1>
                        <h3 className={'space'}>We step up.</h3>
                        <h1>Brilliance.</h1>
                        <h3>We strive for it.</h3>
                    </TextBlock>
                </Container>
            </Wrapper>
            <Wrapper color={'#15171b'}>
                <Container>
                    <TextBlock title={'The team.'} txtcolor={PRIMARY_TEXT_COLOR}>
                        <h2>130 individuals passionate about the future.</h2>
                        <p>
                            We are 130 professionals passionate about building the future. While our roots are in Slovenia, our reach
                            extends across Europe and North America, allowing us to work with startups and enterprises globally. Our team
                            has a wealth of experience in software development, with thousands of development hours across a wide range of
                            projects.
                        </p>
                        <p>
                            We’ve won multiple awards, including being recognized four times for the best digital banking solution in
                            Slovenia. Our development teams have also built digital products with hundreds of thousands of downloads, 5-star
                            reviews, and widespread media attention.
                        </p>
                        <p>
                            We excel in all relevant technologies, from mobile and web development to blockchain and backend systems, always
                            delivering innovative solutions that drive success.
                        </p>
                    </TextBlock>
                </Container>
            </Wrapper>
            {/*
            <Wrapper color={'#15171b'}>
                <TeamImage src={team_image} className="d-none d-md-block" />
                <TeamImage src={team_vertical} className="d-block d-md-none" />
            </Wrapper>*/}
            {/*
            <Wrapper color={'#15171b'}>
                <Container>
                    <TextBlock title={'Leadership.'} txtcolor={PRIMARY_TEXT_COLOR}>
                        <h3>
                            <Link to={'https://si.linkedin.com/in/mihar'}>Miha Rabič</Link>
                        </h3>
                        <h3 className={'mb-4'}>CEO</h3>
                    </TextBlock>
                </Container>
            </Wrapper>*/}
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <ImageGrid
                    images={[clutch, goodfirm, disrupt2016, disrupt2015, unltd, mtfp, nt]}
                    urls={[
                        'https://clutch.co/profile/equaleyes-solutions',
                        'https://www.goodfirms.co/companies/view/4337/equaleyes-solutions-ltd',
                        'https://devpost.com/software/sensei-hub-054x7w',
                        'https://techcrunch.com/2015/12/06/disrupt-london-2015-hackathon-winners',
                        'https://unltd.org.uk/2013/05/04/tine-postuvan-equaleyes-2/',
                        undefined,
                        'http://socialtech.org.uk/projects/equaleyes/',
                    ]}
                />
            </Wrapper>
            <Wrapper color={'#15171b'}>
                <TwoRows txtcolor={PRIMARY_TEXT_COLOR}>
                    <Col xs={12} md={6} xl={4}>
                        <h2>Ljubljana.</h2>
                        <div>Brnčičeva ulica 39</div>
                        <div>1231 Ljubljana - Črnuče</div>
                        <div>Slovenia</div>
                    </Col>
                    <Col xs={12} md={6} xl={4} className={'spaced'}>
                        <h2>Maribor.</h2>
                        <div>Razlagova ulica 4</div>
                        <div>2000 Maribor</div>
                        <div>Slovenia</div>
                    </Col>
                </TwoRows>
            </Wrapper>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <LetsGetStarted bg={PRIMARY_TEXT_COLOR} color={PRIMARY_TITLE_COLOR} />
            </Wrapper>
        </Layout>
    );
};
