import React from 'react';
import styled, { keyframes } from 'styled-components';
import { SECONDARY_BG_COLOR, TEXT_FONT, TITLE_FONT } from '../../constants';
import novaKBM from '../../../static/images/automatic-carousel/NovaKBM.png';
import pattern from '../../../static/images/automatic-carousel/Pattern.png';
import ftech from '../../../static/images/automatic-carousel/Ftech.png';
import rockWallet from '../../../static/images/automatic-carousel/Rock_Wallet.png';
import complex from '../../../static/images/automatic-carousel/Complex.png';
import sideways from '../../../static/images/automatic-carousel/Sideways.png';
import pixelPin from '../../../static/images/automatic-carousel/PixelPin.png';
import libraro from '../../../static/images/automatic-carousel/Libraro.png';
import otpBanka from '../../../static/images/automatic-carousel/OTP_Banka.png';
import eLivestock from '../../../static/images/automatic-carousel/E_Livestock.png';
import autistica from '../../../static/images/automatic-carousel/Autistica.png';
import skaza from '../../../static/images/automatic-carousel/Skaza.png';
import takk from '../../../static/images/automatic-carousel/Takk.png';
import nChain from '../../../static/images/automatic-carousel/nChain.png';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const scrollAnimation = keyframes`
    0% {transform: translateX(0);}
    100% {transform: translateX(-2046px)}
`;

const Carousel = styled.div`
    overflow: hidden;
    position: relative;
    max-width: 100%;

    &::before,
    &::after {
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        content: '';
        height: 240px;
        position: absolute;
        width: 200px;
        z-index: 2;
        display: none;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }
`;

const Track = styled.div`
    animation: ${scrollAnimation} 30s linear infinite;
    display: flex;
    align-items: center;
    height: 46px;
    gap: 50px;
    width: 100%;
`;

const Item = styled.div`
    height: 46px;
`;

const Image = styled.img`
    height: 46px;
    margin-bottom: 32px;
`;

const Title = styled.h1`
    margin-bottom: 32px;
    text-align: center;
    font-family: ${TITLE_FONT};
    color: ${SECONDARY_BG_COLOR};
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 768px) {
        font-size: 32px;
        margin: 0 16px 40px 16px;
    }
`;

const Subtitle = styled.h2`
    margin: 0 16px;
    color: ${SECONDARY_BG_COLOR};
    text-align: center;
    font-family: ${TEXT_FONT};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.478px;
    max-width: 836px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const Line1 = [
    novaKBM,
    pattern,
    ftech,
    rockWallet,
    complex,
    sideways,
    pixelPin,
    libraro,
    otpBanka,
    eLivestock,
    autistica,
    skaza,
    takk,
    nChain,
    novaKBM,
    pattern,
    ftech,
    rockWallet,
    complex,
    sideways,
    pixelPin,
    libraro,
    otpBanka,
    eLivestock,
    autistica,
    skaza,
    takk,
    nChain,
];

export const TestimoniesCarousel = () => {
    return (
        <Wrapper>
            <Carousel>
                <Track>
                    {Line1.map(image => (
                        <Item>
                            <Image src={image} />
                        </Item>
                    ))}
                </Track>
            </Carousel>
        </Wrapper>
    );
};
